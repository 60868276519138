@import url(https://fonts.googleapis.com/css?family=Roboto:300,700);
#react-impressjs {
  line-height: 1;
  font-family:
    Roboto,
    San Francisco,
    \\5FAE\8EDF\6B63\9ED1\9AD4,
    "sans-serif";
  min-height: 100vh;
  background: #d7d7d7;
  background: -webkit-gradient(
    radial,
    50% 50%,
    0,
    50% 50%,
    500,
    from(#f0f0f0),
    to(#9ba5af)
  );
  background: -webkit-radial-gradient(#f0f0f0, #9ba5af);
  background: -moz-radial-gradient(#f0f0f0, #9ba5af);
  background: -ms-radial-gradient(#f0f0f0, #9ba5af);
  background: -o-radial-gradient(#f0f0f0, #9ba5af);
  background: radial-gradient(#f0f0f0, #9ba5af);
}
.step {
  position: relative;
  width: 1000px;
  padding: 20px;
  margin: 20px auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-family:
    Roboto,
    San Francisco,
    \\5FAE\8EDF\6B63\9ED1\9AD4,
    "sans-serif";
  font-size: 48px;
  line-height: 1.5;
}
.impress-enabled .step {
  margin: 0;
  opacity: 0.3;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}
.impress-enabled .step.active {
  opacity: 1;
  cursor: default;
}
.impress-enabled .step a {
  color: hsla(0, 0%, 100%, 0.8);
  text-decoration: none;
  padding: 0 0.4em;
  background: #3e4852;
  border-radius: 0.2em;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.impress-enabled .step a:focus,
.impress-enabled .step a:hover {
  color: #e5b560;
  background: #495561;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-decoration: none;
}
.impress-enabled .step b {
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.impress-enabled .step.present .positioning {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.impress-enabled .step.present .positioning,
.impress-enabled .step.present .positioning-down {
  -webkit-transition-delay: 1.25s;
  -moz-transition-delay: 1.25s;
  -ms-transition-delay: 1.25s;
  -o-transition-delay: 1.25s;
  transition-delay: 1.25s;
}
.impress-enabled .step.present .positioning-down {
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}
.impress-enabled .step.present .rotating {
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
.impress-enabled .step.present .rotating,
.impress-enabled .step.present .rotating-right {
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  -ms-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.impress-enabled .step.present .rotating-right {
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}
.impress-enabled .step.present .scaling {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}
.impress-enabled .step.present .scaling,
.impress-enabled .step.present .scaling-up {
  -webkit-transition-delay: 1.75s;
  -moz-transition-delay: 1.75s;
  -ms-transition-delay: 1.75s;
  -o-transition-delay: 1.75s;
  transition-delay: 1.75s;
}
.impress-enabled .step.present .scaling-up {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.slide {
  display: block;
  line-height: 36px;
  width: 900px;
  height: 700px;
  padding: 40px 60px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  box-shadow:
    0 8px 16px rgba(0, 20, 80, 0.04),
    0 4px 16px rgba(0, 0, 0, 0.08);
  color: #666;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 35px;
  letter-spacing: 1px;
}
.slide:hover {
  box-shadow:
    0 8px 16px rgba(0, 40, 80, 0.08),
    0 8px 24px 5px rgba(0, 0, 0, 0.08);
}
#overview {
  display: none;
}
.impress-on-overview .step {
  opacity: 1;
}
.hint {
  opacity: 0;
  animation: fadeOutUp 0.6s ease forwards;
  color: hsla(0, 0%, 100%, 0.8);
  background-color: #3e4852;
  font-size: 20px;
  text-align: center;
  width: 400px;
  min-height: 100px;
  line-height: 100px;
  position: fixed;
  left: 25px;
  bottom: 14%;
  z-index: 9999;
  border-radius: 3px;
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.3));
}
.hint b {
  color: #e5b560;
}
.mobile-hint {
  opacity: 0;
  animation: fadeOutUp 0.6s ease forwards;
  text-align: center;
  color: #3e4852;
  width: 128px;
  position: fixed;
  left: calc(50% - 64px);
  bottom: 10%;
}
.mobile-hint img {
  display: block;
  margin: 0 auto;
  width: 48px;
}
.mobile-hint span {
  display: block;
  margin-top: 5px;
}
.show .hint,
.show .mobile-hint {
  animation: fadeInDown 0.6s ease 1s forwards;
}
.impress-enabled .hint,
.impress-enabled .mobile-hint {
  display: block;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.slide q {
  display: block;
  font-size: 50px;
  line-height: 72px;
  margin-top: 100px;
}
.slide q strong {
  white-space: nowrap;
}
#title {
  padding: 0;
}
#title .try {
  font-size: 64px;
  position: absolute;
  top: -0.5em;
  left: 1.5em;
  -webkit-transform: translateZ(20px);
  -moz-transform: translateZ(20px);
  -ms-transform: translateZ(20px);
  transform: translateZ(20px);
}
#title .try,
#title h1 {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
#title h1 {
  font-size: 190px;
  -webkit-transform: translateZ(50px);
  -moz-transform: translateZ(50px);
  -ms-transform: translateZ(50px);
  transform: translateZ(50px);
}
#title .footnote {
  font-size: 32px;
}
#big {
  width: 600px;
  text-align: center;
  font-size: 60px;
  line-height: 1;
}
#big b {
  display: block;
  font-size: 250px;
  line-height: 250px;
}
#big .thoughts {
  font-size: 90px;
  line-height: 150px;
}
#tiny {
  text-align: center;
}
#ing,
#tiny {
  width: 500px;
}
#imagination {
  width: 600px;
}
#imagination .imagination {
  font-size: 78px;
}
#source {
  width: 700px;
  padding-bottom: 300px;
  /* background-image: url(); */
  background-position: 100% 100%;
  background-repeat: no-repeat;
}
#source q {
  font-size: 60px;
}
#its-in-3d p {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#its-in-3d b,
#its-in-3d span {
  display: inline-block;
  -webkit-transform: translateZ(40px);
  -moz-transform: translateZ(40px);
  -ms-transform: translateZ(40px);
  transform: translateZ(40px);
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
#its-in-3d .have {
  -webkit-transform: translateZ(-40px);
  -moz-transform: translateZ(-40px);
  -ms-transform: translateZ(-40px);
  transform: translateZ(-40px);
}
#its-in-3d .have,
#its-in-3d .you {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
#its-in-3d .you {
  -webkit-transform: translateZ(20px);
  -moz-transform: translateZ(20px);
  -ms-transform: translateZ(20px);
  transform: translateZ(20px);
}
#its-in-3d .noticed {
  -webkit-transform: translateZ(-40px);
  -moz-transform: translateZ(-40px);
  -ms-transform: translateZ(-40px);
  transform: translateZ(-40px);
}
#its-in-3d .its,
#its-in-3d .noticed {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
#its-in-3d .its {
  -webkit-transform: translateZ(60px);
  -moz-transform: translateZ(60px);
  -ms-transform: translateZ(60px);
  transform: translateZ(60px);
}
#its-in-3d .footnote,
#its-in-3d .in {
  -webkit-transform: translateZ(-10px);
  -moz-transform: translateZ(-10px);
  -ms-transform: translateZ(-10px);
  transform: translateZ(-10px);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
#its-in-3d .footnote {
  font-size: 32px;
}
#its-in-3d.present b,
#its-in-3d.present span {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
/*# sourceMappingURL=react-impressjs.css.map*/
